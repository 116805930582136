import React from 'react';

import { Badge } from '@chakra-ui/react';

type RoundedBadgeProps = {
    colorScheme?: string;
    children: React.ReactNode;
}

const RoundedBadge: React.FC<RoundedBadgeProps> = ({ colorScheme = 'green', children }: RoundedBadgeProps) => (
    <Badge
        textTransform="initial"
        borderRadius={6}
        fontWeight={500}
        colorScheme={colorScheme}
        variant='solid'
        fontSize="xs"
    >
        {children}
    </Badge>
);

export default RoundedBadge;